import api from '../../../../api'

export const FETCH_ECONOMETER_HISTORY_PENDING = 'FETCH_ECONOMETER_HISTORY_PENDING'
export const FETCH_ECONOMETER_HISTORY_FULFILLED = 'FETCH_ECONOMETER_HISTORY_FULFILLED'
export const FETCH_ECONOMETER_HISTORY_REJECTED = 'FETCH_ECONOMETER_HISTORY_REJECTED'

export const CHANGE_ECONOMETER_HISTORY = 'CHANGE_ECONOMETER_HISTORY'

export const DEFAULT_ECONOMETER_HISTORY = 'DEFAULT_ECONOMETER_HISTORY'

export function defaultEconometerHistory() { 
  return dispatch => {
    dispatch({ type: DEFAULT_ECONOMETER_HISTORY })
  }
}

export function changeEconometerHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_ECONOMETER_HISTORY, payload: { name, value } })
  }
}

export function findAllEconometerHistorys(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_ECONOMETER_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllEconometerHistorys {
                  historicoPosicoesEconometro (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    veiculo {
                      placa
                      descricao
                      veiculoTipo {
                        ligadaIcon
                        desligadaIcon
                        panicoIcon
                        terminalIcon
                        misturaIcon
                        descargaIcon
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    numeroLog
                    tipoEquipamento
                    codigoLog
                    dataHoraTelemetria
                    tempoMotorLigado
                    tempoMarchaLenta
                    tempoRpmAbaixo
                    tempoRpmEconomica
                    tempoRpmAcima
                    tempoRpmSuperior
                    tempoRpmMaximo
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_ECONOMETER_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesEconometro } = data
        dispatch({ type: FETCH_ECONOMETER_HISTORY_FULFILLED, payload: historicoPosicoesEconometro });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ECONOMETER_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}
