import api from '../../../../api'
import { toFloat } from '../../../../common'

export const FETCH_VEHICLES_PENDING = 'FETCH_VEHICLES_PENDING'
export const FETCH_VEHICLES_FULFILLED = 'FETCH_VEHICLES_FULFILLED'
export const FETCH_VEHICLES_REJECTED = 'FETCH_VEHICLES_REJECTED'

export const FETCH_VEHICLE_PENDING = 'FETCH_VEHICLE_PENDING'
export const FETCH_VEHICLE_FULFILLED = 'FETCH_VEHICLE_FULFILLED'
export const FETCH_VEHICLE_REJECTED = 'FETCH_VEHICLE_REJECTED'

export const CREATE_VEHICLE_PENDING = 'CREATE_VEHICLE_PENDING'
export const CREATE_VEHICLE_FULFILLED = 'CREATE_VEHICLE_FULFILLED'
export const CREATE_VEHICLE_REJECTED = 'CREATE_VEHICLE_REJECTED'

export const UPDATE_VEHICLE_PENDING = 'UPDATE_VEHICLE_PENDING'
export const UPDATE_VEHICLE_FULFILLED = 'UPDATE_VEHICLE_FULFILLED'
export const UPDATE_VEHICLE_REJECTED = 'UPDATE_VEHICLE_REJECTED'

export const DELETE_VEHICLE_PENDING = 'DELETE_VEHICLE_PENDING'
export const DELETE_VEHICLE_FULFILLED = 'DELETE_VEHICLE_FULFILLED'
export const DELETE_VEHICLE_REJECTED = 'DELETE_VEHICLE_REJECTED'

export const FETCH_CARD_POSITIONS_PENDING = 'FETCH_CARD_POSITIONS_PENDING'
export const FETCH_CARD_POSITIONS_FULFILLED = 'FETCH_CARD_POSITIONS_FULFILLED'
export const FETCH_CARD_POSITIONS_REJECTED = 'FETCH_CARD_POSITIONS_REJECTED'

export const CHANGE_VEHICLE = 'CHANGE_VEHICLE'

export const ADD_VEHICLE = 'ADD_VEHICLE'

export function changeVehicle(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_VEHICLE, payload: { name, value } })
  }
}

export function addVehicle() {
  return dispatch => {
    dispatch({ type: ADD_VEHICLE });
  };
}

export function findAllVehicles(first = 12000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLES_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllVehicles {
                  veiculos (first: ${first} offset: ${offset}) {
                    codigo
                    status
                    descricao
                    placa
                    numeroFrota
                    equipamentoPrimario
                    equipamentoSecundario
                    createdAt
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { veiculos } = data
        dispatch({ type: FETCH_VEHICLES_FULFILLED, payload: veiculos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEHICLES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findAllVehiclesByPlate(placa, first = 1000, offset = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLES_PENDING });

    if (placa.trim().length <= 2) {
      dispatch(findAllVehicles());
      return;
    }

    api({
      method: 'post',         
      data: {
        query: `
                query findAllVehicles {
                  veiculosPorPlaca (search: "${placa}" first: ${first} offset: ${offset}) {
                    codigo
                    status
                    descricao
                    placa
                    numeroFrota
                    equipamentoPrimario
                    equipamentoSecundario
                    createdAt
                    veiculoTipo {
                      ligadaIcon
                      desligadaIcon
                      panicoIcon
                      terminalIcon
                      misturaIcon
                      descargaIcon
                    }
                    perifericoPrimario {
                      serial
                      modelo
                    }
                    perifericoSecundario {
                      serial
                      modelo
                    }
                    clienteMatriz {
                      razaoSocial
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLES_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { veiculosPorPlaca } = data
        dispatch({ type: FETCH_VEHICLES_FULFILLED, payload: veiculosPorPlaca });
        resolve(veiculosPorPlaca);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEHICLES_REJECTED, error });
      resolve(error);
    });

  });

}

export function findVehicle(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_VEHICLE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                    query findVehicle {
                      veiculo (codigo: ${codigo}) {
                        codigo
                        matriz
                        tipoVeiculo
                        perfilPortas
                        equipamentoPrimario
                        equipamentoSecundario
                        garagem
                        grupo
                        placa
                        descricao
                        chassi
                        prefixo
                        marca
                        modelo
                        cor
                        fabricante
                        anoFabricacao
                        numeroFrota
                        status
                        observacoes
                        configuracoes
                        capacidadeTanque
                        tensaoVazio
                        tensao1Quarto
                        tensaoMeio
                        tensao3Quartos
                        tensaoCheio
                        mediaConsumo
                        leituraInversa
                        rpmMarchaLenta
                        rpmEconomicaAbaixo
                        rpmEconomica
                        rpmEconomicaAcima
                        rpmEconomicaMaximo
                        tempoLogMinimo
                        pulsosPorKm
                        odometro
                        velocidadeMinima
                        velocidadeMaxima
                        rpmMinimo
                        rpmMaximo
                        temperaturaMinima
                        temperaturaMaxima
                        analogicaMinima
                        analogicaMaxima
                        anoRelogio
                        tempoLogEntradaUm
                        tempoLogEntradaDois
                        tempoLogEntradaTres
                        tempoLogEntradaQuatro
                        tempoLogEntradaCinco
                        tempoLogEntradaSeis
                        tempoLogEntradaSete
                        coeficienteRpm
                        velocidadeMaximaChuva
                        frequencia100Km
                        aceleracaoBruscaParado
                        aceleracaoBruscaMovimento
                        freadaBrusca
                        velocidadeMinimaSaida
                        velocidadeMaximaSaida
                        rpmMinimoSaida
                        rpmMaximoSaida
                        temperaturaMinimaSaida
                        temperaturaMaximaSaida
                        analogicaMinimaSaida
                        analogicaMaximaSaida
                        entradaUmSaida
                        entradaDoisSaida
                        entradaTresSaida
                        entradaQuatroSaida
                        entradaCincoSaida
                        entradaSeisSaida
                        estadoNormalSaidas
                        filtroLogs
                        tolBanguela
                        tolVelocidadeSeco
                        tolVelocidadeChuva
                        tolExcessoRpm
                        tolFreadaBrusca
                        tolAceleracaoBruscaMovimento
                        tolAceleracaoBruscaParado
                        tolExcessoTemperatura
                        tempoLeituraEntradaUm
                        tempoLeituraEntradaDois
                        tempoLeituraEntradaTres
                        tempoLeituraEntradaQuatro
                        tempoLeituraEntradaCinco
                        tempoLeituraEntradaSeis
                        tempoLeituraEntradaSete
                        sensorTemperaturaMinima
                        sensorTemperaturaMaxima
                        sensorUmidadeMinima
                        sensorUmidadeMaxima
                        sensorTemperaturaMinimaUm
                        sensorTemperaturaMaximaUm
                        sensorTemperaturaMinimaDois
                        sensorTemperaturaMaximaDois
                        sensorTemperaturaMinimaTres
                        sensorTemperaturaMaximaTres
                        sensorTemperaturaMinimaQuatro
                        sensorTemperaturaMaximaQuatro
                        valorMensalidade
                        valorChip
                        versaoTelemetria
                        createdAt
                        clienteMatriz {
                          codigo
                          razaoSocial
                        }
                        veiculoPorta { 
                          telemetria
                          rastreador
                        }
                        perifericoPrimario {
                          serial
                          modelo
                        }
                        perifericoSecundario {
                          serial
                          modelo
                        }
                      }
                    }
                    `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_VEHICLE_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { veiculo } = data
        dispatch({ type: FETCH_VEHICLE_FULFILLED, payload: veiculo });
        resolve(veiculo);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_VEHICLE_REJECTED, error });
      reject(error);
    });

  });

}

export function createVehicle(vehicle) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: CREATE_VEHICLE_PENDING });

    api({
      method: 'post',        
      data: {
        query: `
          mutation createVehicle {
            createVeiculo (input: {
              codigo: ${vehicle.codigo}
              matriz: ${vehicle.matriz}
              tipoVeiculo: ${vehicle.tipoVeiculo}
              perfilPortas: ${vehicle.perfilPortas}
              equipamentoPrimario: ${vehicle.equipamentoPrimario}
              equipamentoSecundario: ${vehicle.equipamentoSecundario}
              garagem: ${vehicle.garagem}
              grupo: ${vehicle.grupo}
              placa: "${vehicle.placa}"
              descricao: "${vehicle.descricao}"
              chassi: "${vehicle.chassi}"
              prefixo: "${vehicle.prefixo}"
              marca: "${vehicle.marca}"
              modelo: "${vehicle.modelo}"
              cor: "${vehicle.cor}"
              fabricante: ${vehicle.fabricante}
              anoFabricacao: ${vehicle.anoFabricacao}
              numeroFrota: ${vehicle.numeroFrota}
              status: ${vehicle.status}
              observacoes: "${vehicle.observacoes}"
              configuracoes: ${vehicle.configuracoes}
              capacidadeTanque: ${vehicle.capacidadeTanque}
              tensaoVazio: ${toFloat(vehicle.tensaoVazio)}
              tensao1Quarto: ${toFloat(vehicle.tensao1Quarto)}
              tensaoMeio: ${toFloat(vehicle.tensaoMeio)}
              tensao3Quartos: ${toFloat(vehicle.tensao3Quartos)}
              tensaoCheio: ${toFloat(vehicle.tensaoCheio)}
              mediaConsumo: ${toFloat(vehicle.mediaConsumo)}
              leituraInversa: ${vehicle.leituraInversa}
              rpmMarchaLenta: ${vehicle.rpmMarchaLenta}
              rpmEconomicaAbaixo: ${vehicle.rpmEconomicaAbaixo}
              rpmEconomica: ${vehicle.rpmEconomica}
              rpmEconomicaAcima: ${vehicle.rpmEconomicaAcima}
              rpmEconomicaMaximo: ${vehicle.rpmEconomicaMaximo}
              tempoLogMinimo: ${vehicle.tempoLogMinimo}
              pulsosPorKm: ${vehicle.pulsosPorKm}
              odometro: ${toFloat(vehicle.odometro)}
              velocidadeMinima: ${vehicle.velocidadeMinima}
              velocidadeMaxima: ${vehicle.velocidadeMaxima}
              rpmMinimo: ${vehicle.rpmMinimo}
              rpmMaximo: ${vehicle.rpmMaximo}
              temperaturaMinima: ${vehicle.temperaturaMinima}
              temperaturaMaxima: ${vehicle.temperaturaMaxima}
              analogicaMinima: ${vehicle.analogicaMinima}
              analogicaMaxima: ${vehicle.analogicaMaxima}
              anoRelogio: ${vehicle.anoRelogio}
              tempoLogEntradaUm: ${vehicle.tempoLogEntradaUm}
              tempoLogEntradaDois: ${vehicle.tempoLogEntradaDois}
              tempoLogEntradaTres: ${vehicle.tempoLogEntradaTres}
              tempoLogEntradaQuatro: ${vehicle.tempoLogEntradaQuatro}
              tempoLogEntradaCinco: ${vehicle.tempoLogEntradaCinco}
              tempoLogEntradaSeis: ${vehicle.tempoLogEntradaSeis}
              tempoLogEntradaSete: ${vehicle.tempoLogEntradaSete}
              coeficienteRpm: ${vehicle.coeficienteRpm}
              velocidadeMaximaChuva: ${vehicle.velocidadeMaximaChuva}
              frequencia100Km: ${vehicle.frequencia100Km}
              aceleracaoBruscaParado: ${vehicle.aceleracaoBruscaParado}
              aceleracaoBruscaMovimento: ${vehicle.aceleracaoBruscaMovimento}
              freadaBrusca: ${vehicle.freadaBrusca}
              velocidadeMinimaSaida: ${vehicle.velocidadeMinimaSaida}
              velocidadeMaximaSaida: ${vehicle.velocidadeMaximaSaida}
              rpmMinimoSaida: ${vehicle.rpmMinimoSaida}
              rpmMaximoSaida: ${vehicle.rpmMaximoSaida}
              temperaturaMinimaSaida: ${vehicle.temperaturaMinimaSaida}
              temperaturaMaximaSaida: ${vehicle.temperaturaMaximaSaida}
              analogicaMinimaSaida: ${vehicle.analogicaMinimaSaida}
              analogicaMaximaSaida: ${vehicle.analogicaMaximaSaida}
              entradaUmSaida: ${vehicle.entradaUmSaida}
              entradaDoisSaida: ${vehicle.entradaDoisSaida}
              entradaTresSaida: ${vehicle.entradaTresSaida}
              entradaQuatroSaida: ${vehicle.entradaQuatroSaida}
              entradaCincoSaida: ${vehicle.entradaCincoSaida}
              entradaSeisSaida: ${vehicle.entradaSeisSaida}
              estadoNormalSaidas: ${vehicle.estadoNormalSaidas}
              filtroLogs: ${vehicle.filtroLogs}
              tolBanguela: "${vehicle.tolBanguela}"
              tolVelocidadeSeco: "${vehicle.tolVelocidadeSeco}"
              tolVelocidadeChuva: "${vehicle.tolVelocidadeChuva}"
              tolExcessoRpm: "${vehicle.tolExcessoRpm}"
              tolFreadaBrusca: ${vehicle.tolFreadaBrusca}
              tolAceleracaoBruscaMovimento: ${vehicle.tolAceleracaoBruscaMovimento}
              tolAceleracaoBruscaParado: ${vehicle.tolAceleracaoBruscaParado}
              tolExcessoTemperatura: ${vehicle.tolExcessoTemperatura}
              tempoLeituraEntradaUm: "${vehicle.tempoLeituraEntradaUm}"
              tempoLeituraEntradaDois: "${vehicle.tempoLeituraEntradaDois}"
              tempoLeituraEntradaTres: "${vehicle.tempoLeituraEntradaTres}"
              tempoLeituraEntradaQuatro: "${vehicle.tempoLeituraEntradaQuatro}"
              tempoLeituraEntradaCinco: "${vehicle.tempoLeituraEntradaCinco}"
              tempoLeituraEntradaSeis: "${vehicle.tempoLeituraEntradaSeis}"
              tempoLeituraEntradaSete: "${vehicle.tempoLeituraEntradaSete}"
              sensorTemperaturaMinima: ${vehicle.sensorTemperaturaMinima}
              sensorTemperaturaMaxima: ${vehicle.sensorTemperaturaMaxima}
              sensorUmidadeMinima: ${vehicle.sensorUmidadeMinima}
              sensorUmidadeMaxima: ${vehicle.sensorUmidadeMaxima}
              sensorTemperaturaMinimaUm: ${vehicle.sensorTemperaturaMinimaUm}
              sensorTemperaturaMaximaUm: ${vehicle.sensorTemperaturaMaximaUm}
              sensorTemperaturaMinimaDois: ${vehicle.sensorTemperaturaMinimaDois}
              sensorTemperaturaMaximaDois: ${vehicle.sensorTemperaturaMaximaDois}
              sensorTemperaturaMinimaTres: ${vehicle.sensorTemperaturaMinimaTres}
              sensorTemperaturaMaximaTres: ${vehicle.sensorTemperaturaMaximaTres}
              sensorTemperaturaMinimaQuatro: ${vehicle.sensorTemperaturaMinimaQuatro}
              sensorTemperaturaMaximaQuatro: ${vehicle.sensorTemperaturaMaximaQuatro}
              valorMensalidade: ${toFloat(vehicle.valorMensalidade)}
              valorChip: ${toFloat(vehicle.valorChip)}
            } ) {
              codigo
              matriz
              tipoVeiculo
              perfilPortas
              equipamentoPrimario
              equipamentoSecundario
              garagem
              grupo
              placa
              descricao
              chassi
              prefixo
              marca
              modelo
              cor
              fabricante
              anoFabricacao
              numeroFrota
              status
              observacoes
              configuracoes
              capacidadeTanque
              tensaoVazio
              tensao1Quarto
              tensaoMeio
              tensao3Quartos
              tensaoCheio
              mediaConsumo
              leituraInversa
              rpmMarchaLenta
              rpmEconomicaAbaixo
              rpmEconomica
              rpmEconomicaAcima
              rpmEconomicaMaximo
              tempoLogMinimo
              pulsosPorKm
              odometro
              velocidadeMinima
              velocidadeMaxima
              rpmMinimo
              rpmMaximo
              temperaturaMinima
              temperaturaMaxima
              analogicaMinima
              analogicaMaxima
              anoRelogio
              tempoLogEntradaUm
              tempoLogEntradaDois
              tempoLogEntradaTres
              tempoLogEntradaQuatro
              tempoLogEntradaCinco
              tempoLogEntradaSeis
              tempoLogEntradaSete
              coeficienteRpm
              velocidadeMaximaChuva
              frequencia100Km
              aceleracaoBruscaParado
              aceleracaoBruscaMovimento
              freadaBrusca
              velocidadeMinimaSaida
              velocidadeMaximaSaida
              rpmMinimoSaida
              rpmMaximoSaida
              temperaturaMinimaSaida
              temperaturaMaximaSaida
              analogicaMinimaSaida
              analogicaMaximaSaida
              entradaUmSaida
              entradaDoisSaida
              entradaTresSaida
              entradaQuatroSaida
              entradaCincoSaida
              entradaSeisSaida
              estadoNormalSaidas
              filtroLogs
              tolBanguela
              tolVelocidadeSeco
              tolVelocidadeChuva
              tolExcessoRpm
              tolFreadaBrusca
              tolAceleracaoBruscaMovimento
              tolAceleracaoBruscaParado
              tolExcessoTemperatura
              tempoLeituraEntradaUm
              tempoLeituraEntradaDois
              tempoLeituraEntradaTres
              tempoLeituraEntradaQuatro
              tempoLeituraEntradaCinco
              tempoLeituraEntradaSeis
              tempoLeituraEntradaSete
              sensorTemperaturaMinima
              sensorTemperaturaMaxima
              sensorUmidadeMinima
              sensorUmidadeMaxima
              sensorTemperaturaMinimaUm
              sensorTemperaturaMaximaUm
              sensorTemperaturaMinimaDois
              sensorTemperaturaMaximaDois
              sensorTemperaturaMinimaTres
              sensorTemperaturaMaximaTres
              sensorTemperaturaMinimaQuatro
              sensorTemperaturaMaximaQuatro
              valorMensalidade
              valorChip
              clienteMatriz {
                codigo
                razaoSocial
              }
              perifericoPrimario {
                serial
                modelo
              }
              perifericoSecundario {
                serial
                modelo
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: CREATE_VEHICLE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { createVeiculo } = data
        dispatch({ type: CREATE_VEHICLE_FULFILLED, payload: createVeiculo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: CREATE_VEHICLE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function updateVehicle(vehicle) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: UPDATE_VEHICLE_PENDING });

    api({
      method: 'post',      
      data: {
        query: `
          mutation UpdateVehicle {
            updateVeiculo (input: {
              codigo: ${vehicle.codigo}
              matriz: ${vehicle.matriz}
              tipoVeiculo: ${vehicle.tipoVeiculo}
              perfilPortas: ${vehicle.perfilPortas}
              equipamentoPrimario: ${vehicle.equipamentoPrimario}
              equipamentoSecundario: ${vehicle.equipamentoSecundario}
              garagem: ${vehicle.garagem}
              grupo: ${vehicle.grupo}
              placa: "${vehicle.placa}"
              descricao: "${vehicle.descricao}"
              chassi: "${vehicle.chassi}"
              prefixo: "${vehicle.prefixo}"
              marca: "${vehicle.marca}"
              modelo: "${vehicle.modelo}"
              cor: "${vehicle.cor}"
              fabricante: ${vehicle.fabricante}
              anoFabricacao: ${vehicle.anoFabricacao}
              numeroFrota: ${vehicle.numeroFrota}
              status: ${vehicle.status}
              observacoes: "${vehicle.observacoes}"
              configuracoes: ${vehicle.configuracoes}
              capacidadeTanque: ${vehicle.capacidadeTanque}
              tensaoVazio: ${toFloat(vehicle.tensaoVazio)}
              tensao1Quarto: ${toFloat(vehicle.tensao1Quarto)}
              tensaoMeio: ${toFloat(vehicle.tensaoMeio)}
              tensao3Quartos: ${toFloat(vehicle.tensao3Quartos)}
              tensaoCheio: ${toFloat(vehicle.tensaoCheio)}
              mediaConsumo: ${toFloat(vehicle.mediaConsumo)}
              leituraInversa: ${vehicle.leituraInversa}
              rpmMarchaLenta: ${vehicle.rpmMarchaLenta}
              rpmEconomicaAbaixo: ${vehicle.rpmEconomicaAbaixo}
              rpmEconomica: ${vehicle.rpmEconomica}
              rpmEconomicaAcima: ${vehicle.rpmEconomicaAcima}
              rpmEconomicaMaximo: ${vehicle.rpmEconomicaMaximo}
              tempoLogMinimo: ${vehicle.tempoLogMinimo}
              pulsosPorKm: ${vehicle.pulsosPorKm}
              odometro: ${toFloat(vehicle.odometro)}
              velocidadeMinima: ${vehicle.velocidadeMinima}
              velocidadeMaxima: ${vehicle.velocidadeMaxima}
              rpmMinimo: ${vehicle.rpmMinimo}
              rpmMaximo: ${vehicle.rpmMaximo}
              temperaturaMinima: ${vehicle.temperaturaMinima}
              temperaturaMaxima: ${vehicle.temperaturaMaxima}
              analogicaMinima: ${vehicle.analogicaMinima}
              analogicaMaxima: ${vehicle.analogicaMaxima}
              anoRelogio: ${vehicle.anoRelogio}
              tempoLogEntradaUm: ${vehicle.tempoLogEntradaUm}
              tempoLogEntradaDois: ${vehicle.tempoLogEntradaDois}
              tempoLogEntradaTres: ${vehicle.tempoLogEntradaTres}
              tempoLogEntradaQuatro: ${vehicle.tempoLogEntradaQuatro}
              tempoLogEntradaCinco: ${vehicle.tempoLogEntradaCinco}
              tempoLogEntradaSeis: ${vehicle.tempoLogEntradaSeis}
              tempoLogEntradaSete: ${vehicle.tempoLogEntradaSete}
              coeficienteRpm: ${vehicle.coeficienteRpm}
              velocidadeMaximaChuva: ${vehicle.velocidadeMaximaChuva}
              frequencia100Km: ${vehicle.frequencia100Km}
              aceleracaoBruscaParado: ${vehicle.aceleracaoBruscaParado}
              aceleracaoBruscaMovimento: ${vehicle.aceleracaoBruscaMovimento}
              freadaBrusca: ${vehicle.freadaBrusca}
              velocidadeMinimaSaida: ${vehicle.velocidadeMinimaSaida}
              velocidadeMaximaSaida: ${vehicle.velocidadeMaximaSaida}
              rpmMinimoSaida: ${vehicle.rpmMinimoSaida}
              rpmMaximoSaida: ${vehicle.rpmMaximoSaida}
              temperaturaMinimaSaida: ${vehicle.temperaturaMinimaSaida}
              temperaturaMaximaSaida: ${vehicle.temperaturaMaximaSaida}
              analogicaMinimaSaida: ${vehicle.analogicaMinimaSaida}
              analogicaMaximaSaida: ${vehicle.analogicaMaximaSaida}
              entradaUmSaida: ${vehicle.entradaUmSaida}
              entradaDoisSaida: ${vehicle.entradaDoisSaida}
              entradaTresSaida: ${vehicle.entradaTresSaida}
              entradaQuatroSaida: ${vehicle.entradaQuatroSaida}
              entradaCincoSaida: ${vehicle.entradaCincoSaida}
              entradaSeisSaida: ${vehicle.entradaSeisSaida}
              estadoNormalSaidas: ${vehicle.estadoNormalSaidas}
              filtroLogs: ${vehicle.filtroLogs}
              tolBanguela: "${vehicle.tolBanguela}"
              tolVelocidadeSeco: "${vehicle.tolVelocidadeSeco}"
              tolVelocidadeChuva: "${vehicle.tolVelocidadeChuva}"
              tolExcessoRpm: "${vehicle.tolExcessoRpm}"
              tolFreadaBrusca: ${vehicle.tolFreadaBrusca}
              tolAceleracaoBruscaMovimento: ${vehicle.tolAceleracaoBruscaMovimento}
              tolAceleracaoBruscaParado: ${vehicle.tolAceleracaoBruscaParado}
              tolExcessoTemperatura: ${vehicle.tolExcessoTemperatura}
              tempoLeituraEntradaUm: "${vehicle.tempoLeituraEntradaUm}"
              tempoLeituraEntradaDois: "${vehicle.tempoLeituraEntradaDois}"
              tempoLeituraEntradaTres: "${vehicle.tempoLeituraEntradaTres}"
              tempoLeituraEntradaQuatro: "${vehicle.tempoLeituraEntradaQuatro}"
              tempoLeituraEntradaCinco: "${vehicle.tempoLeituraEntradaCinco}"
              tempoLeituraEntradaSeis: "${vehicle.tempoLeituraEntradaSeis}"
              tempoLeituraEntradaSete: "${vehicle.tempoLeituraEntradaSete}"
              sensorTemperaturaMinima: ${vehicle.sensorTemperaturaMinima}
              sensorTemperaturaMaxima: ${vehicle.sensorTemperaturaMaxima}
              sensorUmidadeMinima: ${vehicle.sensorUmidadeMinima}
              sensorUmidadeMaxima: ${vehicle.sensorUmidadeMaxima}
              sensorTemperaturaMinimaUm: ${vehicle.sensorTemperaturaMinimaUm}
              sensorTemperaturaMaximaUm: ${vehicle.sensorTemperaturaMaximaUm}
              sensorTemperaturaMinimaDois: ${vehicle.sensorTemperaturaMinimaDois}
              sensorTemperaturaMaximaDois: ${vehicle.sensorTemperaturaMaximaDois}
              sensorTemperaturaMinimaTres: ${vehicle.sensorTemperaturaMinimaTres}
              sensorTemperaturaMaximaTres: ${vehicle.sensorTemperaturaMaximaTres}
              sensorTemperaturaMinimaQuatro: ${vehicle.sensorTemperaturaMinimaQuatro}
              sensorTemperaturaMaximaQuatro: ${vehicle.sensorTemperaturaMaximaQuatro}
              valorMensalidade: ${toFloat(vehicle.valorMensalidade)}
              valorChip: ${toFloat(vehicle.valorChip)}
            } ) {
              codigo
              matriz
              tipoVeiculo
              perfilPortas
              equipamentoPrimario
              equipamentoSecundario
              garagem
              grupo
              placa
              descricao
              chassi
              prefixo
              marca
              modelo
              cor
              fabricante
              anoFabricacao
              numeroFrota
              status
              observacoes
              configuracoes
              capacidadeTanque
              tensaoVazio
              tensao1Quarto
              tensaoMeio
              tensao3Quartos
              tensaoCheio
              mediaConsumo
              leituraInversa
              rpmMarchaLenta
              rpmEconomicaAbaixo
              rpmEconomica
              rpmEconomicaAcima
              rpmEconomicaMaximo
              tempoLogMinimo
              pulsosPorKm
              odometro
              velocidadeMinima
              velocidadeMaxima
              rpmMinimo
              rpmMaximo
              temperaturaMinima
              temperaturaMaxima
              analogicaMinima
              analogicaMaxima
              anoRelogio
              tempoLogEntradaUm
              tempoLogEntradaDois
              tempoLogEntradaTres
              tempoLogEntradaQuatro
              tempoLogEntradaCinco
              tempoLogEntradaSeis
              tempoLogEntradaSete
              coeficienteRpm
              velocidadeMaximaChuva
              frequencia100Km
              aceleracaoBruscaParado
              aceleracaoBruscaMovimento
              freadaBrusca
              velocidadeMinimaSaida
              velocidadeMaximaSaida
              rpmMinimoSaida
              rpmMaximoSaida
              temperaturaMinimaSaida
              temperaturaMaximaSaida
              analogicaMinimaSaida
              analogicaMaximaSaida
              entradaUmSaida
              entradaDoisSaida
              entradaTresSaida
              entradaQuatroSaida
              entradaCincoSaida
              entradaSeisSaida
              estadoNormalSaidas
              filtroLogs
              tolBanguela
              tolVelocidadeSeco
              tolVelocidadeChuva
              tolExcessoRpm
              tolFreadaBrusca
              tolAceleracaoBruscaMovimento
              tolAceleracaoBruscaParado
              tolExcessoTemperatura
              tempoLeituraEntradaUm
              tempoLeituraEntradaDois
              tempoLeituraEntradaTres
              tempoLeituraEntradaQuatro
              tempoLeituraEntradaCinco
              tempoLeituraEntradaSeis
              tempoLeituraEntradaSete
              sensorTemperaturaMinima
              sensorTemperaturaMaxima
              sensorUmidadeMinima
              sensorUmidadeMaxima
              sensorTemperaturaMinimaUm
              sensorTemperaturaMaximaUm
              sensorTemperaturaMinimaDois
              sensorTemperaturaMaximaDois
              sensorTemperaturaMinimaTres
              sensorTemperaturaMaximaTres
              sensorTemperaturaMinimaQuatro
              sensorTemperaturaMaximaQuatro
              valorMensalidade
              valorChip
              clienteMatriz {
                razaoSocial
              }
              perifericoPrimario {
                serial
                modelo
              }
              perifericoSecundario {
                serial
                modelo
              }
            }
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: UPDATE_VEHICLE_REJECTED, payload: errors });
        resolve({ status: false, error: errors[0].message });
      } else {
        const { updateVeiculo } = data
        dispatch({ type: UPDATE_VEHICLE_FULFILLED, payload: updateVeiculo });
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: UPDATE_VEHICLE_REJECTED, errors });
      resolve({ status: false, error: errors.message });
    });
  });
}

export function removeVehicle(codigo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_VEHICLE_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
          mutation RemoveVehicle {
            deleteVeiculo (codigo: ${codigo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_VEHICLE_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteVeiculo } = data
        dispatch({ type: DELETE_VEHICLE_FULFILLED, payload: deleteVeiculo });
        dispatch(findAllVehicles());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_VEHICLE_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}

export function findAllCardPositions(vehicle = 0) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_CARD_POSITIONS_PENDING });

    api({
      method: 'post',         
      data: {
        query: `
                query findAllCardPositions {
                  posicoesCartao(veiculo: ${vehicle}) {
                    posicao
                    serialRfid
                    dataHora
                    cartao {
                      serial
                      cartaoMotorista {
                        nome
                      }
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_CARD_POSITIONS_REJECTED, payload: errors });
        resolve(errors);
      } else {
        const { posicoesCartao } = data
        dispatch({ type: FETCH_CARD_POSITIONS_FULFILLED, payload: posicoesCartao });
        resolve(posicoesCartao);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_CARD_POSITIONS_REJECTED, error });
      resolve(error);
    });

  });

}
