import api from '../../../../api'

export const FETCH_TRACKING_HISTORY_PENDING = 'FETCH_TRACKING_HISTORY_PENDING'
export const FETCH_TRACKING_HISTORY_FULFILLED = 'FETCH_TRACKING_HISTORY_FULFILLED'
export const FETCH_TRACKING_HISTORY_REJECTED = 'FETCH_TRACKING_HISTORY_REJECTED'

export const FETCH_TRACKING_HISTORY_WITH_STOPPEDS_PENDING = 'FETCH_TRACKING_HISTORY_WITH_STOPPEDS_PENDING'
export const FETCH_TRACKING_HISTORY_WITH_STOPPEDS_FULFILLED = 'FETCH_TRACKING_HISTORY_WITH_STOPPEDS_FULFILLED'
export const FETCH_TRACKING_HISTORY_WITH_STOPPEDS_REJECTED = 'FETCH_TRACKING_HISTORY_WITH_STOPPEDS_REJECTED'

export const DELETE_TRACKING_HISTORY_PENDING = 'DELETE_TRACKING_HISTORY_PENDING'
export const DELETE_TRACKING_HISTORY_FULFILLED = 'DELETE_TRACKING_HISTORY_FULFILLED'
export const DELETE_TRACKING_HISTORY_REJECTED = 'DELETE_TRACKING_HISTORY_REJECTED'

export const CHANGE_TRACKING_HISTORY = 'CHANGE_TRACKING_HISTORY'

export const DEFAULT_TRACKING_HISTORY = 'DEFAULT_TRACKING_HISTORY'

export function defaultTrackingHistory() { 
  return dispatch => {
    dispatch({ type: DEFAULT_TRACKING_HISTORY })
  }
}

export function changeTrackingHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TRACKING_HISTORY, payload: { name, value } })
  }
}

export function findAllTrackingHistorys(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TRACKING_HISTORY_PENDING });

    api({ 
      method: 'post',
      data: {
        query: `
                query findAllTrackingHistorys {
                  historicoPosicoes (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    garagem: ${filter.garagem}
                    velocidadeTipo: ${filter.velocidadeTipo}
                    velocidadeValor: ${filter.velocidadeValor}
                    entrada1: ${filter.entrada1}
                    entrada2: ${filter.entrada2}
                    entrada3: ${filter.entrada3}
                    entrada4: ${filter.entrada4}
                    saida1: ${filter.saida1}
                    saida2: ${filter.saida2}
                    saida3: ${filter.saida3}
                    saida4: ${filter.saida4}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                    modulo: ${filter.modulo}
                  } first: ${first} offset: ${offset}) {
                    veiculo {
                      placa
                      descricao
                      veiculoTipo {
                        ligadaIcon
                        desligadaIcon
                        panicoIcon
                        terminalIcon
                        misturaIcon
                        descargaIcon
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                    motorista
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    ignicao
                    panico
                    gps
                    gprs
                    entrada1
                    entrada2
                    entrada3
                    entrada4
                    saida1
                    saida2
                    saida3
                    saida4
                    temperatura
                    latitude
                    longitude
                    velocidade
                    rpm
                    hodometro
                    horimetro
                    direcao
                    ponto
                    area
                    rota 
                    tensaoInterna
                    tensaoExterna
                    sequencia
                    endereco
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TRACKING_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoes } = data
        dispatch({ type: FETCH_TRACKING_HISTORY_FULFILLED, payload: historicoPosicoes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TRACKING_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllTrackingHistorysWithStoppings(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TRACKING_HISTORY_WITH_STOPPEDS_PENDING });

    api({ 
      method: 'post',
      data: {
        query: `
                query findAllTrackingHistorysWithStoppings {
                  historicoPosicoesComParadas (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    garagem: ${filter.garagem}
                    velocidadeTipo: ${filter.velocidadeTipo}
                    velocidadeValor: ${filter.velocidadeValor}
                    entrada1: ${filter.entrada1}
                    entrada2: ${filter.entrada2}
                    entrada3: ${filter.entrada3}
                    entrada4: ${filter.entrada4}
                    saida1: ${filter.saida1}
                    saida2: ${filter.saida2}
                    saida3: ${filter.saida3}
                    saida4: ${filter.saida4}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    posicoes {
                      veiculo {
                        placa
                        descricao
                        veiculoTipo {
                          ligadaIcon
                          desligadaIcon
                          panicoIcon
                          terminalIcon
                          misturaIcon
                          descargaIcon
                        }
                        clienteMatriz {
                          razaoSocial
                        }
                        veiculoGaragem {
                          descricao
                        }
                      }
                      motorista
                      modulo
                      dataHoraServidor
                      dataHoraGps
                      ignicao
                      panico
                      gps
                      gprs
                      entrada1
                      entrada2
                      entrada3
                      entrada4
                      saida1
                      saida2
                      saida3
                      saida4
                      temperatura
                      latitude
                      longitude
                      velocidade
                      rpm
                      hodometro
                      horimetro
                      direcao
                      ponto
                      area
                      rota 
                      tensaoInterna
                      tensaoExterna
                      sequencia
                      endereco
                    }
                    paradas {
                      latitude
                      longitude
                      dataHoraInicio
                      dataHoraFim
                      tempoParado
                    }
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TRACKING_HISTORY_WITH_STOPPEDS_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesComParadas } = data
        dispatch({ type: FETCH_TRACKING_HISTORY_WITH_STOPPEDS_FULFILLED, 
          payload: historicoPosicoesComParadas });
        resolve(historicoPosicoesComParadas);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TRACKING_HISTORY_WITH_STOPPEDS_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllTracking120Historys(vehicle = 0, first = 1000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TRACKING_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTracking120Historys {
                  historico120Posicoes (veiculo: ${vehicle} first: ${first} offset: ${offset}) {
                    veiculo {
                      placa
                      descricao
                      veiculoTipo {
                        ligadaIcon
                        desligadaIcon
                        panicoIcon
                        terminalIcon
                        misturaIcon
                        descargaIcon
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao
                      }
                    }
                    motorista
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    ignicao
                    panico
                    gps
                    gprs
                    entrada1
                    entrada2
                    entrada3
                    entrada4
                    saida1
                    saida2
                    saida3
                    saida4
                    temperatura
                    latitude
                    longitude
                    velocidade
                    rpm
                    hodometro
                    horimetro
                    direcao
                    ponto
                    area
                    rota
                    sequencia
                    endereco
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TRACKING_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historico120Posicoes } = data
        dispatch({ type: FETCH_TRACKING_HISTORY_FULFILLED, payload: historico120Posicoes });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TRACKING_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}

export function removeTrackingHistory(veiculo) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_TRACKING_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation RemoveTrackingHistory {
            deleteHistoricoPosicoes (veiculo: ${veiculo})
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_TRACKING_HISTORY_REJECTED, payload: errors });
        resolve({ status: false, error: errors });
      } else {
        const { deleteHistoricoPosicoes } = data
        dispatch({ type: DELETE_TRACKING_HISTORY_FULFILLED, payload: deleteHistoricoPosicoes });
        dispatch(findAllTrackingHistorys());
        resolve({ status: true });
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_TRACKING_HISTORY_REJECTED, errors });
      resolve({ status: false, error: errors });
    });
  });
}
