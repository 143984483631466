import {

    FETCH_FUEL_HISTORY_PENDING,
    FETCH_FUEL_HISTORY_FULFILLED,
    FETCH_FUEL_HISTORY_REJECTED,

    FETCH_FUEL_EXCLUSION_PENDING,
    FETCH_FUEL_EXCLUSION_FULFILLED,
    FETCH_FUEL_EXCLUSION_REJECTED,

    DELETE_FUEL_HISTORY_PENDING,
    DELETE_FUEL_HISTORY_FULFILLED,
    DELETE_FUEL_HISTORY_REJECTED,

    CHANGE_FUEL_HISTORY,
    DEFAULT_FUEL_HISTORY

} from './fuelHistoryActions'

const moment = require('moment');
const initialState = {
    loading: false,
    listOfFuelHistory: [],
    listOfFuelExclusion: [],
    fuelHistory: {
        veiculo: 0,
        motorista: 0,
        garagem: 0,
        matriz: 0,
        dataInicial: moment().utc().format("DD/MM/YYYY"),
        horaInicial: '00:00:00',
        dataFinal: moment().utc().format("DD/MM/YYYY"),
        horaFinal: moment().format("HH:mm:ss")
    },
    error: null,
}

export function fuelHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_FUEL_HISTORY: 
            return Object.assign({}, state, {
                ...initialState,
            })
        case CHANGE_FUEL_HISTORY:
            return Object.assign({}, state, {
                fuelHistory: {
                    ...state.fuelHistory,
                    [action.payload.name]: action.payload.value
                }
            })
        case FETCH_FUEL_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfFuelHistory: [],
            })
        case FETCH_FUEL_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfFuelHistory: action.payload
            })
        case FETCH_FUEL_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfFuelHistory: [],
                error: action.payload
            })

        case FETCH_FUEL_EXCLUSION_PENDING:
            return Object.assign({}, state, {
                loading: true,
                listOfFuelExclusion: [],
            })
        case FETCH_FUEL_EXCLUSION_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
                listOfFuelExclusion: action.payload
            })
        case FETCH_FUEL_EXCLUSION_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                listOfFuelExclusion: [],
                error: action.payload
            })

        case DELETE_FUEL_HISTORY_PENDING:
            return Object.assign({}, state, {
                loading: true,
            })
        case DELETE_FUEL_HISTORY_FULFILLED:
            return Object.assign({}, state, {
                loading: false,
            })
        case DELETE_FUEL_HISTORY_REJECTED:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}