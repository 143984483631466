import api from '../../../../api'

export const FETCH_FUEL_HISTORY_PENDING = 'FETCH_FUEL_HISTORY_PENDING'
export const FETCH_FUEL_HISTORY_FULFILLED = 'FETCH_FUEL_HISTORY_FULFILLED'
export const FETCH_FUEL_HISTORY_REJECTED = 'FETCH_FUEL_HISTORY_REJECTED'

export const FETCH_FUEL_EXCLUSION_PENDING = 'FETCH_FUEL_EXCLUSION_PENDING'
export const FETCH_FUEL_EXCLUSION_FULFILLED = 'FETCH_FUEL_EXCLUSION_FULFILLED'
export const FETCH_FUEL_EXCLUSION_REJECTED = 'FETCH_FUEL_EXCLUSION_REJECTED'

export const DELETE_FUEL_HISTORY_PENDING = 'DELETE_FUEL_HISTORY_PENDING'
export const DELETE_FUEL_HISTORY_FULFILLED = 'DELETE_FUEL_HISTORY_FULFILLED'
export const DELETE_FUEL_HISTORY_REJECTED = 'DELETE_FUEL_HISTORY_REJECTED'

export const CHANGE_FUEL_HISTORY = 'CHANGE_FUEL_HISTORY'
export const DEFAULT_FUEL_HISTORY = 'DEFAULT_FUEL_HISTORY'

export function defaultFuelHistory() { 
  return dispatch => {
    dispatch({ type: DEFAULT_FUEL_HISTORY })
  }
}

export function changeFuelHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_FUEL_HISTORY, payload: { name, value } })
  }
}

export function findAllFuelHistorys(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_FUEL_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: ` 
                query findAllFuelHistorys {
                  historicoAbastecimentos (input: {
                    matriz: ${filter.matriz}
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    garagem: ${filter.garagem}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    combustivel
                    abastecimentos {
                      codigo
                      dataMovimento
                      horaMovimento
                      veiculoAbastecido {
                        placa
                        descricao
                      }
                      odometro
                      distancia
                      media
                      motoristaVeiculo {
                        nome
                      }
                      combustivel
                      quantidade
                      valorLitro
                      valorTotal
                      vale
                      observacoes
                    }
                    quantidade
                    valorTotal
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_FUEL_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoAbastecimentos } = data
        dispatch({ type: FETCH_FUEL_HISTORY_FULFILLED, payload: historicoAbastecimentos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_FUEL_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}

export function findAllFuelExclusion(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_FUEL_EXCLUSION_PENDING });

    api({
      method: 'post',
      data: {
        query: ` 
                query findAllFuelExclusion {
                  historicoExclusaoAbastecimentos (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    garagem: ${filter.garagem}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    combustivel
                    abastecimentos {
                      codigo
                      dataMovimento
                      horaMovimento
                      veiculoAbastecido {
                        placa
                        descricao
                      }
                      odometro
                      distancia
                      media
                      motoristaVeiculo {
                        nome
                      }
                      combustivel
                      quantidade
                      valorLitro
                      valorTotal
                      vale
                      observacoes
                      dataExclusao
                      motivoExclusao
                      usuarioExclusao {
                        nome
                      }
                    }
                    quantidade
                    valorTotal
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_FUEL_EXCLUSION_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoExclusaoAbastecimentos } = data
        dispatch({ type: FETCH_FUEL_EXCLUSION_FULFILLED, payload: historicoExclusaoAbastecimentos });
        resolve(data);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_FUEL_EXCLUSION_REJECTED, error });
      reject(error);
    });

  });

}

export function removeLaunchFuel(code, reason) {

  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: DELETE_FUEL_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
          mutation RemoveLaunchFuel {
            deleteAbastecimento (codigo: ${code} motivo: "${reason}")
          }
          `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: DELETE_FUEL_HISTORY_REJECTED, payload: errors });
        reject(false);
      } else {
        const { deleteAbastecimento } = data
        dispatch({ type: DELETE_FUEL_HISTORY_FULFILLED, payload: deleteAbastecimento });
        resolve(deleteAbastecimento);
      }
    }).catch((errors) => {
      dispatch({ type: DELETE_FUEL_HISTORY_REJECTED, errors });
      reject(false);
    });
  });
}
