import api from '../../../../api'

export const FETCH_TELEMETRY_HISTORY_PENDING = 'FETCH_TELEMETRY_HISTORY_PENDING'
export const FETCH_TELEMETRY_HISTORY_FULFILLED = 'FETCH_TELEMETRY_HISTORY_FULFILLED'
export const FETCH_TELEMETRY_HISTORY_REJECTED = 'FETCH_TELEMETRY_HISTORY_REJECTED'

export const CHANGE_TELEMETRY_HISTORY = 'CHANGE_TELEMETRY_HISTORY'

export const DEFAULT_TELEMETRY_HISTORY = 'DEFAULT_TELEMETRY_HISTORY'

export function defaultTelemetryHistory() { 
  return dispatch => {
    dispatch({ type: DEFAULT_TELEMETRY_HISTORY })
  }
}

export function changeTelemetryHistory(name, value) {
  return dispatch => {
    dispatch({ type: CHANGE_TELEMETRY_HISTORY, payload: { name, value } })
  }
}

export function findAllTelemetryHistorys(filter, first = 12000, offset = 0) {
  
  return dispatch => new Promise((resolve, reject) => {

    dispatch({ type: FETCH_TELEMETRY_HISTORY_PENDING });

    api({
      method: 'post',
      data: {
        query: `
                query findAllTelemetryHistorys {
                  historicoPosicoesTelemetria (input: {
                    veiculo: ${filter.veiculo}
                    motorista: ${filter.motorista}
                    garagem: ${filter.garagem}
                    evento: ${filter.evento}
                    velocidadeTipo: ${filter.velocidadeTipo}
                    velocidadeValor: ${filter.velocidadeValor}
                    rpmTipo: ${filter.rpmTipo}
                    rpmValor: ${filter.rpmValor}
                    entrada1: ${filter.entrada1}
                    entrada2: ${filter.entrada2}
                    entrada3: ${filter.entrada3} 
                    entrada4: ${filter.entrada4}
                    entrada5: ${filter.entrada5}
                    entrada6: ${filter.entrada6}
                    entrada7: ${filter.entrada7}
                    entrada8: ${filter.entrada8}
                    saida1: ${filter.saida1}
                    saida2: ${filter.saida2}
                    saida3: ${filter.saida3}
                    saida4: ${filter.saida4}
                    dataHoraInicial: "${filter.dataHoraInicial}"
                    dataHoraFinal: "${filter.dataHoraFinal}"
                  } first: ${first} offset: ${offset}) {
                    veiculo {
                      placa
                      descricao
                      veiculoTipo {
                        ligadaIcon
                        desligadaIcon
                        panicoIcon
                        terminalIcon
                        misturaIcon
                        descargaIcon
                      }
                      clienteMatriz {
                        razaoSocial
                      }
                      veiculoGaragem {
                        descricao 
                      }
                      capacidadeTanque
                      tensaoVazio
                      tensao1Quarto
                      tensaoMeio
                      tensao3Quartos
                      tensaoCheio
                      leituraInversa
                    }
                    modulo
                    dataHoraServidor
                    dataHoraGps
                    latitude
                    longitude
                    numeroLog
                    tipoEquipamento
                    codigoLog
                    descricaoEvento
                    dataHoraTelemetria
                    odometro
                    velocidade
                    rpm
                    temperatura
                    valorAnalogico
                    entradaUm
                    entradaDois
                    entradaTres
                    entradaQuatro
                    entradaCinco
                    entradaSeis
                    entradaSete
                    entradaOito
                    saidaUm
                    saidaDois
                    saidaTres
                    saidaQuatro
                    serialRfid
                    motorista {
                      nome
                    }
                    contadorEntradaUm
                    contadorEntradaDois
                    contadorEntradaTres
                    contadorEntradaQuatro
                    contadorEntradaCinco
                    contadorEntradaSeis
                    contadorEntradaSete
                    sequencia
                  }
                }
                `
      }
    }).then((result) => {
      const { errors, data } = result.data
      if (errors) {
        dispatch({ type: FETCH_TELEMETRY_HISTORY_REJECTED, payload: errors });
        reject(errors);
      } else {
        const { historicoPosicoesTelemetria } = data
        dispatch({ type: FETCH_TELEMETRY_HISTORY_FULFILLED, payload: historicoPosicoesTelemetria });
        resolve(historicoPosicoesTelemetria);
      }
    }).catch((error) => {
      dispatch({ type: FETCH_TELEMETRY_HISTORY_REJECTED, error });
      reject(error);
    });

  });

}
